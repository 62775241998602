
import React from "react"

import PickuppointForm from "../../components/negosyonow/forms/pickuppoint";

const formFields = [
	//{"label":"Code", "field": "nnpickup_code", "value": "", "input": "textbox", "mode": "unique"},
	{"label":"Name", "field": "nnpickup_name", "value": "", "input": "textbox", "mode": "required"},
	{"label":"Type", "field": "onsorderformfulfillment_id", "value": "", "input": "combo", "mode": "readonly", "options": [
		{"value":4,"display":"Bus Shipping"},
		{"value":2,"display":"Delivery"},
		{"value":1,"display":"Others"},
		{"value":3,"display":"Pick up"},
	]},
];

const PickuppointEditPage = ({location}) => {

	return <PickuppointForm
				location={location}
				allowadd={false}
				allowdelete={false}
				custombasicformfields={formFields}
			/>


}


export default PickuppointEditPage;
